import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__BlockVip extends VScroll_Item {
  controller;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._call = () => {
      this.loop();
    }

    this.onShow = () => {
      gsap.ticker.add(this._call);
    };

    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };

    this.onMove = () => {};
  }

  loop() {
    
  }

  dispose() {
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h);
  }
}

Scroll._registerClass('BlockVip', ScrollItem__BlockVip);