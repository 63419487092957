import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Scene from '../3D/Scene';
import RenderTargetSketch from '../3D/RenderTargetSketch';
import { LazyPictures } from '../components/LazyPictures';
import { LazyVideos } from '../components/LazyVideos';
import { EventCalendars } from '../components/EventCalendars';
import { Stickers } from '../components/Stickers';
import { Popups } from '../components/Popups';
import Header from '../_app/cuchillo/layout/Header';
import Movable from '../components/Movable';

export default class Default extends Page {
  constructor() {
    super();

    Acordions.init();
    Videos.init();
    LazyPictures.init();
    LazyVideos.init();
    EventCalendars.init();
    Stickers.init();
    Movable.init();
    Popups.init();
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: this._hasSmoothScroll, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    Header.getNextEvent();

    if (this.isFirstTime) {
      Scene.start();
      RenderTargetSketch.start();
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    LazyPictures.dispose();
    LazyVideos.dispose();
    EventCalendars.dispose();
    Movable.dispose();
    //Stickers.dispose();
    Popups.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
