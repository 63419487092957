import WebGLObject from './WebGLObject';
import { DRACO_LOADER, SETTINGS } from '../../../3D/constants';
import TexturesController from './TexturesController';
import GeometryController from './GeometryController';

export default class ModelGLB extends WebGLObject {
    object;
    diffusion;
    normal;
    roughness;
    metalness;
    envMap;

    model;
    matcap;
    factor;

    set material(mat) {
        this._material = mat;
        this.mesh.material = mat;
    }
    get material() { return this.mesh.material || this._material }

    set renderOrder(order) { if (this.mesh) this.mesh.renderOrder = order; }
    get renderOrder() { return this.mesh ? this.mesh.renderOrder : 0; }

    constructor(opts = {}) {
        super(opts);

        this._material = opts.material;
        this.model = opts.model;
        this.matcap = opts.matcap;
        this.diffusion = opts.diffusion;
        this.normal = opts.normal;
        this.roughness = opts.roughness;
        this.metalness = opts.metalness;
        this.envMap = opts.envMap;

        this.sizeFactor = opts.cols ? opts.cols : SETTINGS.factor;
    }

    init() {
        return new Promise((resolve, reject) => {
            GeometryController.load(
                {
                    src: this.model,
                    call: (gltf) => {
                        this.object = gltf;

                        this.mesh = gltf.scene.children[0];
                        this.add(gltf.scene);

                        if (this._material) {
                            this.mesh.material = this._material;
                            this.material.needsUpdate = true;
                        }

                        this.size.copy(this.mesh.scale);

                        this.resize();
                        this.loadTextures();

                        resolve();
                    }
                },
                undefined,
                (error) => {
                    reject(error);
                });
        });
    }

    loadTextures() {
        if (this.matcap) {
            TexturesController.load({
                src: this.matcap,
                material: this.material,
                attribute: 'matcap'
            });
        }
    }

    resize(offsetX = 0, offsetY = 0, offsetScale = 1) {
        if (!this.object) return;
        const { x, y, width, height } = this.dom.getBoundingClientRect();

        if (this.hasMove) {
            const position = this.domPositionTo3D(x, y);
            this.position.x = (position.x + width * .5) - offsetX;
            this.position.y = (position.y - height * .5) - offsetY;
            this.pos.x = this.position.x;
            this.pos.y = this.position.y;
        } else {
            this.position.x = 0;
            this.position.y = 0;
            this.pos.x = 0;
            this.pos.y = 0;
        }

        const factor = width / this.sizeFactor;
        this.mesh.scale.set((factor * this.size.x) / offsetScale, (factor * this.size.y) / offsetScale, (factor * this.size.z) / offsetScale);
    }

    dispose() {
        super.dispose();

        this._material = null;
        this.model = null;
        this.matcap = null;
        this.diffusion = null;
        this.normal = null;
        this.roughness = null;
        this.metalness = null;
        this.envMap = null;
        this.sizeFactor = null;
    }
}
