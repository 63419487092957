import { ShaderMaterial, DoubleSide } from "three";
import CanvasColorTexture from "../textures/CanvasColorTexture";

export const DISTORTEDPIXEL_VERTEX = `
    varying vec2 vUv;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const DISTORTEDPIXEL_FRAGMENT = `
    uniform float opacity;
    uniform vec2 resolution;
    uniform sampler2D dataTexture;
    uniform sampler2D texture1;

    varying vec2 vUv;

    float PI = 3.141592653589793238;
    float scaleCenter = 0.5;

    void main() {
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);

        vec4 offset = texture2D(dataTexture, vUv);
        gl_FragColor = texture2D(texture1, newUV - 0.02 * offset.rg);
    }
`;

export const DISTORTION_SETTINGS = {
    cols: 100,
    radius: 1,
    strength: 0.01,//.1,
    relaxation: 0,//.9,
    opacity: .2,
    renderTarget: true
}

export const DISTORTEPIXEL_UNIFORMS = {
    texture1: { type: 't', value: new CanvasColorTexture().texture },
    dataTexture: { type: 't', value: new CanvasColorTexture().texture },
    opacity: { type: 'f', value: DISTORTION_SETTINGS.opacity },
    resolution: {
        type: 'v2',
        value: { x: 1, y: 1 }
    }
};

export default class DistortedPixelMaterial extends ShaderMaterial {
    constructor() {
        super({
            extensions: {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: DISTORTEPIXEL_UNIFORMS,
            fragmentShader: DISTORTEDPIXEL_FRAGMENT,
            vertexShader: DISTORTEDPIXEL_VERTEX,
            transparent: true,
            side: DoubleSide
        });
    }
}
