import { OrthographicCamera, PlaneGeometry, Mesh, LinearFilter, Scene, WebGLRenderTarget, DataTexture, RGBAFormat, FloatType, NearestFilter } from 'three';

import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import DistortedPixelMaterial, { DISTORTION_SETTINGS } from '../_app/cuchillo/3D/materials/DistortedPixelMaterial';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export default class RenderTargetSketch extends WebGLSketch {
    static renderScene;

    static renderPlane;
    static renderPlaneGeometry;
    static renderPlaneMaterial;
    static renderPlaneScene;
    static renderPlaneCamera;
    static renderSceneWidth;
    static renderSceneHeight;

    static firstResize = true;
    static cols = 0;
    static rows = 0;
    static gridTexture;
    static tick = 0;
    static mouse = {
        x: 0,
        y: 0,
        prevX: 0,
        prevY: 0,
        vX: 0,
        vY: 0
    };

    static calls = {
        mouseMove: e => this.mouseMove(e)
    }

    static init() {
        super.init();

        this.renderSceneWidth = window.innerWidth;
        this.renderSceneHeight = window.innerHeight;

        const parameters = {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            format: RGBAFormat,
            stencilBuffer: false
        };

        this.renderScene = new WebGLRenderTarget(this.renderSceneWidth, this.renderSceneHeight, parameters);

        this.generateGrid();

        this.renderPlaneGeometry = new PlaneGeometry(1, 1);
        this.renderPlaneMaterial = new DistortedPixelMaterial();

        this.renderPlaneMaterial.uniforms.texture1.value = this.renderScene.texture;
        // this.renderPlaneMaterial.uniforms.texture1.value = new TextureLoader().load("/assets/images/4.jpg");
        this.renderPlaneMaterial.uniforms.dataTexture.value = this.gridTexture;

        this.renderPlane = new Mesh(this.renderPlaneGeometry, this.renderPlaneMaterial);

        this.renderPlaneScene = new Scene();
        this.renderPlaneScene.add(this.renderPlane);
        this.renderPlaneCamera = new OrthographicCamera(
            -window.innerWidth / 2,
            window.innerWidth / 2,
            window.innerHeight / 2,
            -window.innerHeight / 2,
            -1,
            1
        );
    }

    static mouseMove(e) {
        this.mouse.x = e.clientX / this.renderSceneWidth;
        this.mouse.y = e.clientY / this.renderSceneHeight;

        this.mouse.vX = this.mouse.x - this.mouse.prevX;
        this.mouse.vY = this.mouse.y - this.mouse.prevY;

        this.mouse.prevX = this.mouse.x
        this.mouse.prevY = this.mouse.y;
    }

    static addEventListeners() {
        super.addEventListeners();

        if (isSmartphone) return;
        window.addEventListener(Basics.moveEvent, this.calls.mouseMove)
    }

    static removeEventListeners() {
        super.removeEventListeners();

        if (isSmartphone) return;
        window.removeEventListener(Basics.moveEvent, this.calls.mouseMove)
    }

    static generateGrid() {
        const cellW = this.renderSceneWidth / DISTORTION_SETTINGS.cols;
        this.cols = DISTORTION_SETTINGS.cols;
        this.rows = this.renderSceneHeight / cellW;

        const size = this.cols * this.rows;
        const data = new Float32Array(4 * size);

        for (let i = 0; i < size; i++) {
            let r = Math.random() * 255 - 125;
            let r1 = Math.random() * 255 - 125;
            const stride = i * 4;
            data[stride] = r;
            data[stride + 1] = r1;
            data[stride + 2] = r;
            data[stride + 3] = 255;
        }

        this.gridTexture = new DataTexture(data, this.cols, this.rows, RGBAFormat, FloatType);
        this.gridTexture.magFilter = this.gridTexture.minFilter = NearestFilter;

        if (this.renderPlaneMaterial) {
            this.renderPlaneMaterial.uniforms.dataTexture.value = this.gridTexture;
            this.renderPlaneMaterial.uniforms.dataTexture.value.needsUpdate = true;
        }
    }

    static updateDataTexture() {
        let data = this.gridTexture.image.data;

        for (let i = 0; i < data.length; i += 4) {
            data[i] *= DISTORTION_SETTINGS.relaxation;
            data[i + 1] *= DISTORTION_SETTINGS.relaxation;
        }

        let gridMouseX = this.cols * this.mouse.x;
        let gridMouseY = this.rows * (1 - this.mouse.y);
        let maxDistX = this.cols * DISTORTION_SETTINGS.radius;
        let maxDistY = this.rows * DISTORTION_SETTINGS.radius;
        let aspect = this.renderSceneHeight / this.renderSceneWidth;

        for (let i = 0; i < this.cols; i++) {
            for (let j = 0; j < this.rows; j++) {

                let distanceX = (gridMouseX - i) ** 2 / aspect;
                let distanceY = (gridMouseY - j) ** 2;
                let distance = distanceX + distanceY;
                let maxDistSq = maxDistX ** 2 + maxDistY ** 2;

                if (distance < maxDistSq) {
                    let index = 4 * (i + this.cols * j);

                    let power = Math.sqrt(maxDistX * maxDistY) / Math.sqrt(distance);
                    power = Maths.clamp(power, 0, 10);

                    data[index] += DISTORTION_SETTINGS.strength * 100 * this.mouse.vX * power;
                    data[index + 1] -= DISTORTION_SETTINGS.strength * 100 * this.mouse.vY * power;
                }
            }
        }

        this.mouse.vX *= 0.9;
        this.mouse.vY *= 0.9;
        this.gridTexture.needsUpdate = true
    }

    static update() {
        this.updateDataTexture();
    }

    static render() {
        if (!this.renderScene || !DISTORTION_SETTINGS.renderTarget) {
            super.render();
            return;
        }

        this.renderer.setRenderTarget(this.renderScene);
        this.renderer.render(this.scene, this.camera);

        this.renderer.setRenderTarget(null);
        this.renderer.render(this.renderPlaneScene, this.renderPlaneCamera);
    }

    static resize() {
        super.resize();

        if(isSmartphone && this.renderSceneHeight >= this.domElement.offsetHeight && !this.firstResize) return;
         
        Metrics.RESIZE_MOBILE = false;

        this.firstResize = false;

        this.renderSceneWidth = this.domElement.offsetWidth;
        this.renderSceneHeight = this.domElement.offsetHeight;

        this.renderScene.setSize(this.renderSceneWidth, this.renderSceneHeight);

        this.renderPlane.scale.set(this.renderSceneWidth, this.renderSceneHeight, 1);

        this.renderPlaneCamera.left = -this.renderSceneWidth / 2;
        this.renderPlaneCamera.right = this.renderSceneWidth / 2;
        this.renderPlaneCamera.top = this.renderSceneHeight / 2;
        this.renderPlaneCamera.bottom = -this.renderSceneHeight / 2;
        this.renderPlaneCamera.aspect = this.renderSceneWidth / this.renderSceneHeight;
        this.renderPlaneCamera.updateProjectionMatrix();

        this.generateGrid();
    }
}
