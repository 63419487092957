import { Power3 } from 'gsap';

import _Header from '../_app/cuchillo/layout/Header';
import { Ease } from '../_app/cuchillo/utils/Ease';

export default class Header extends _Header {
  static options = {
    show: {
      duration: 1,
      delay: 0,
      ease: Ease.EASE_CUCHILLO_IN_OUT
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  // static show(__call) {}
  // static hide(__call) {}
}


