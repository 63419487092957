import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import DistortedImage from '../_app/cuchillo/3D/DistortedImage';
import RenderTargetSketch from '../3D/RenderTargetSketch';

class ScrollItem__Footer extends VScroll_Item {
  logo;

  _calls = {
    loop: () => this.loop()
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.init3D();

    this.onShow = () => {
      gsap.ticker.add(this._calls.loop);
      document.body.classList.add("--footer-on-viewport");
    };
    this.onHide = () => {
      gsap.ticker.remove(this._calls.loop);
      document.body.classList.remove("--footer-on-viewport");
    };
    this.onMove = () => {};
  }

  init3D() {
    // AMNESIA IMAGE
    const logo = GetBy.selector('[data-3d-target]', this.item)[0];
    this.logo = new DistortedImage({
      dom: logo,
      diffuse: '/assets/images/texture-footer-2.jpg',
      size: {
        x: 1440,
        y: 600,
      },
    });

    RenderTargetSketch.group.add(this.logo);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    this.logo.update();
  }

  resize(__w, __h) {
    super.resize(__w, __h);

    this.logo.resize();
  }

  dispose() {
    super.dispose();
    gsap.ticker.remove(this._calls.loop);
    RenderTargetSketch.group.remove(this.logo);
  }
}

Scroll._registerClass('footer', ScrollItem__Footer);