import { Mesh, Object3D, Vector3 } from "three";

import { Metrics } from "../core/Metrics";
import { Maths } from "../utils/Maths";

export default class WebGLObject extends Object3D {
  dom;
  size = new Vector3();
  rot = new Vector3();
  pos = new Vector3();
  mouse = new Vector3();
  mesh;
  material;
  geometry;
  hasMove = true;

  constructor(opts = {}) {
    super();

    this.visible = opts.visible == false ? false : true;
    this.geometry = opts.geometry ?? opts.geometry;
    this.material = opts.material ?? opts.material;
    this.dom = opts.dom ?? opts.dom;
    this.hasMove = opts.hasMove !== undefined ? opts.hasMove : true;

    if (opts.size) {
      this.size.copy(opts.size);
    } else if (this.dom.getAttribute('width')) {
      this.size.x = parseInt(this.dom.getAttribute('width'));
      this.size.y = parseInt(this.dom.getAttribute('height'));
    } else {
      const { width, height } = this.dom.getBoundingClientRect();
      this.size.x = width;
      this.size.y = height;
    }

    const defaults = {
      rotFactor: 1,
      xFactor: 1,
      yFactor: 1,
      zFactor: 1
    }

    this.opts = {
      ...defaults,
      ...opts
    };

    this.hasDirectMove = this.opts.xFactor + this.opts.yFactor + this.opts.zFactor + this.opts.rotFactor === 4;

    // this.lookAt = new Vector3(this.position);
    // this.lookAtdom = new Vector3(this.position);
  }

  init() {
    this.mesh = new Mesh(this.geometry, this.material);
    this.add(this.mesh);
    this.visible = true;

    if (this.opts.size) this.mesh.scale.set(this.opts.size.x, this.opts.size.y, this.opts.size.z);
  }

  update() {
    if (!this.visible) return;

    if (this.hasDirectMove) {
      this.position.x = this.pos.x;
      this.position.y = this.pos.y;
      this.position.z = this.pos.z;

      this.rotation.x = this.rot.x;
      this.rotation.y = this.rot.y;
      this.rotation.z = this.rot.z;
    } else {
      this.position.x = Maths.lerp(this.position.x, this.pos.x, this.opts.xFactor);
      this.position.y = Maths.lerp(this.position.y, this.pos.y, this.opts.yFactor);
      this.position.z = Maths.lerp(this.position.z, this.pos.z, this.opts.zFactor);

      this.rotation.x = Maths.lerp(this.rotation.x, this.rot.x, this.opts.rotFactor);
      this.rotation.y = Maths.lerp(this.rotation.y, this.rot.y, this.opts.rotFactor);
      this.rotation.z = Maths.lerp(this.rotation.z, this.rot.z, this.opts.rotFactor);
    }
  }

  resize(x, y, z = 1) {
    this.mesh.scale.set(x, y, z);
  }

  domPositionTo3D(__x, __y) {
    const x = -1 * Metrics.CENTER_X + __x;
    const y = Metrics.CENTER_Y - __y;

    return {
      x,
      y,
      z: 0
    };
  }

  dispose() {
    this.remove(this.mesh);
    if (this.geometry) this.geometry.dispose();
    if (this.material) this.material.dispose();
  }
}
