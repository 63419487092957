import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import BezierEasing from 'bezier-easing';
import InfiniteScroll__Item from '../_app/cuchillo/scroll/InfiniteScroll__Item';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';


class ScrollItem__SliderParties extends VScroll_Item {
    _call;
    _slider;
    _yOld = 0;
    
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
        
        if(!GetBy.class('__holder', __link)[0]) return;
        
        this.container = __link;
        this.holder = GetBy.class('__holder', this.container)[0];
        this._slider = new InfiniteScroll(InfiniteScroll.AXIS_X, {
            domResize: this.container,
            appendContainer: this.holder,
            container: this.holder,
            multiplicator: 1,
            minSpeed: 0,
            inverted: false,
            gap: 0,
            itemClass: ScrollItem__SliderParties__Item,
            hasVirtuaScroll: false,
            hasInteraction: true
        });
                    
        this._call = () => this.loop();
        this.onShow = () => {
            this._slider.show();
            this._slider.enabled = true;
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            this._slider.enabled = false;
            gsap.ticker.remove(this._call);
        };
    }

    resize(w,h) {
        super.resize(w,h);
        if(this._slider) this._slider.resize();
    }

    loop() {
        if(this._slider) {
            this._slider.check({deltaY:this.y - this._yOld});
            this._yOld = this.y;
            this._slider.loop();
        }
    }

    dispose() {
        if(this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderParties__Item extends InfiniteScroll__Item {
    _card;
    _limits = {}
    
    constructor(__d, __i, __axis = InfiniteScroll.AXIS_Y) {
        super(__d, __i, __axis, true);

        this._card = GetBy.selector('.card', __d)[0];
                       
        this.setupLimits();
        this.resetHook();
    }

    drawHook() {
        if (!this._card) return;

        this.move();
    }

    resetHook(){
        if (!this._card) return;

        this.setupLimits();
    }

    move() {
        const progress = Maths.normalize(-.5, .5, this.progressInside);
        const rotation = this._limits.p1;
        
        //Z
        const z = -Math.abs(progress * 100);
        this._z = z;
        this.dom.style.zIndex = String(Math.round(z));

        //SCALE
        const scale = 1 + .3 * (1 - Math.abs(progress));

        this._card.style[CSS.transform] = 
            CSS.rotate3D(0, 0, 1, rotation * progress) + " " 
            + CSS.translate3D(0, this._limits.y * Math.abs(progress), 0) + " " 
            + CSS.scale3D(scale, scale, scale);
    }

    setupLimits() {
        const mod = Math.random() > .5? 1 : -1;
        this._limits = {
            p0: Maths.maxminRandom(25, 10) * mod,
            p1: Maths.maxminRandom(25, 10) * -mod,
            y: isSmartphone? Metrics.parseSize("70fpx") : Metrics.parseSize("250fpx")
        }
    }

    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('SliderParties', ScrollItem__SliderParties);