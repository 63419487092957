import { GetBy } from '../_app/cuchillo/core/Element';
import { Functions } from '../_app/cuchillo/utils/Functions';

export class LazyVideos {
  static _videos = {};
  static _observer = null;
  static _options = {
    rootMargin: '0px 0px 100px 0px',
    threshold: 0
  };
    
  static init() {
    this.setupObserver();
    this.getVideos();
  };

  static getVideos() {
    let counter = 0;
    [...GetBy.selector(".auto-video.lazy")].map(dom => {
      const id = 'lazy-video-' + counter;
      dom.setAttribute('id', id);
      const video = new LazyVideo(dom);
      this._videos[id] = video;
      this._observer.observe(dom);
      counter++;
    });
  };

  static setupObserver() {
    if(this._observer) {
      this._observer.disconnect();
    }
    this._observer = new IntersectionObserver((entries) => { this.checkObserver(entries) }, this._options); 
  }

  static checkObserver(entries, observer) {
    entries.forEach(entry => {
        const id = Functions.getId(entry.target);
        const video = this._videos[id];
        
        if(entry.isIntersecting) {
            video.lazyload();
            this._observer.unobserve(entry.target);
        }
    });
  }

  static dispose() {
    if(this._observer) {  this._observer.disconnect();  }
    
    Object.entries(this._videos).map((item) => {item[1].dispose()});    

    this._videos = {}
    this._observer = null;
  }
};


export default class LazyVideo {
    _container;
    _video;
    _source;
    _onVideoLoadHandler;

    constructor(__video) {
        this._container = __video;
        this._video = GetBy.selector("video", this._container)[0];
        this._source = GetBy.selector("source", this._container)[0];
        this._onVideoLoadHandler = this.onVideoLoad.bind(this);
    }
  
    lazyload() { 
        this._video.addEventListener('loadeddata', this._onVideoLoadHandler);
        let src = this._source.getAttribute('data-src');
        this._source.setAttribute('src', src);
        this._source.removeAttribute('data-src');
        this._video.load();
    }

    onVideoLoad() {
        this._container.classList.add('lazy-loaded');
        this._container.classList.remove('lazy');
        this._video.removeEventListener('loadeddata', this._onVideoLoadHandler);
    }

    dispose() {
        this._container = null;
        this._video = null;
        this._source = null;
        this._onVideoLoadHandler = null;
    }
}