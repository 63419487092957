import { FrontSide, ShaderMaterial } from 'three';
import CanvasColorTexture from '../textures/CanvasColorTexture';

export const DISTORTED_IMAGE_VERTEX = `
    varying vec2 vUv;
    uniform float time;

    void main() {
        vUv = uv;

        // Deformación en forma de onda en los ejes X e Y
        // vec3 pos = position;
        // pos.x += sin(pos.y * 10.0 + time) * 0.1;
        // pos.y += sin(pos.x * 10.0 + time) * 0.1;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const DISTORTED_IMAGE_FRAGMENT = `
    precision mediump float;

    #define PI 3.1415926538

    uniform float waveIntensityX;
    uniform float waveIntensityY;
    uniform float displacementPeriodX;
    uniform float displacementPeriodY;
    uniform float timeScale;
    uniform float seed;
    uniform float maskBegX;
    uniform float maskEndX;
    uniform float maskBegY;
    uniform float maskEndY;
    uniform float opacity;
    uniform vec2 resolution;

    // Texture
    uniform sampler2D texture1;
    uniform float imageScale;

    varying vec2 vUv;

    float scaleCenter = 0.5;

    void main() {
        vec2 waveUV = vUv;
        
        // waveUV.y *= 1. - abs(vUv.x);

        float waveX = sin(waveUV.y * displacementPeriodX + seed * timeScale) * waveIntensityX;
        float waveY = sin(waveUV.x * displacementPeriodY + seed * timeScale) * waveIntensityY;

        // float maskX = smoothstep(maskBegX, 1. - maskEndX, waveUV.x);
        // float maskY = smoothstep(maskBegY, 1. - maskEndY, waveUV.y);
        // waveUV.x += waveX * maskX;
        // waveUV.y += waveY * maskY;
        waveUV.x += waveX;
        waveUV.y += waveY;

        // Applies aspect ratio to plane
        vec2 newUV = (waveUV - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);  // For image texture

        // Gets new centered coords for the texture and scales the image
        vec2 textureUVScale = (newUV - scaleCenter) * imageScale + scaleCenter;

        // Gets texture pixel color
        vec4 textureColor = texture2D(texture1, textureUVScale);

        if (textureColor.a < 0.05) { discard; }

        gl_FragColor = vec4(textureColor.rgb, textureColor.a * opacity);
    }
`;

// export const DISTORTED_IMAGE_FRAGMENT = `
//     precision mediump float;

//     #define PI 3.1415926538

//     uniform float waveIntensityX;
//     uniform float waveIntensityY;
//     uniform float displacementPeriodX;
//     uniform float displacementPeriodY;
//     uniform float timeScale;
//     uniform float seed;
//     uniform float maskBegX;
//     uniform float maskEndX;
//     uniform float maskBegY;
//     uniform float maskEndY;
//     uniform float opacity;
//     uniform vec2 resolution;

//     // Texture
//     uniform sampler2D texture1;
//     uniform float imageScale;

//     varying vec2 vUv;

//     float scaleCenter = 0.5;

//     void main() {
//         // REMOVE ---- MASK
//         // vec2 waveUV = vUv;

//         // // Máscara suave para el eje X
//         // float maskX = smoothstep(maskBegX, maskBegX + 0.1, waveUV.x) * (1.0 - smoothstep(1.0 - maskEndX - 0.1, 1.0 - maskEndX, waveUV.x));
//         // // Máscara suave para el eje Y (opcional, puede ajustarse similar al eje X)
//         // float maskY = smoothstep(maskBegY, maskBegY + 0.1, waveUV.y) * (1.0 - smoothstep(1.0 - maskEndY - 0.1, 1.0 - maskEndY, waveUV.y));

//         // // Modula la waveIntensity según la máscara
//         // float modulatedWaveIntensityX = waveIntensityX * maskX;
//         // float modulatedWaveIntensityY = waveIntensityY * maskY;

//         // // Aplica la distorsión
//         // float waveX = sin(waveUV.y * displacementPeriodX + seed * timeScale) * modulatedWaveIntensityX;
//         // float waveY = sin(waveUV.x * displacementPeriodY + seed * timeScale) * modulatedWaveIntensityY;

//         // waveUV.x += waveX;
//         // waveUV.y += waveY;
//         // REMOVE ---- MASK

//         vec2 waveUV = vUv;

//         // Aplica la distorsión en los ejes X e Y
//         float waveX = sin(waveUV.y * displacementPeriodX + seed * timeScale) * waveIntensityX;
//         float waveY = sin(waveUV.x * displacementPeriodY + seed * timeScale) * waveIntensityY;

//         waveUV.x += waveX;
//         waveUV.y += waveY;

//         // Combina los valores de waveX y waveY para crear un valor de intensidad
//         float intensity = (waveX + waveY) / 2.0;

//         // Colores para el gradiente
//         vec3 color1 = vec3(254.0 / 255.0, 8.0 / 255.0, 80.0 / 255.0); // #FE0850
//         vec3 color2 = vec3(0.0); // #000000 (black)
//         vec3 color3 = vec3(243.0 / 255.0, 252.0 / 255.0, 10.0 / 255.0); // #F3FC0A

//         vec3 color;

//         // Aplica el color según la intensidad
//         if (intensity < -0.5) {
//             color = mix(color1, color2, smoothstep(-1.0, -0.5, intensity));
//         } else if (intensity < 0.5) {
//             color = color2;
//         } else {
//             color = mix(color2, color3, smoothstep(0.5, 1.0, intensity));
//         }

//         // gl_FragColor = vec4(color, opacity);

//         // Applies aspect ratio to plane
//         vec2 newUV = (waveUV - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);  // For image texture

//         // Gets new centered coords for the texture and scales the image
//         vec2 textureUVScale = (newUV - scaleCenter) * imageScale + scaleCenter;

//         // Gets texture pixel color
//         vec4 textureColor = texture2D(texture1, textureUVScale);

//         gl_FragColor = vec4(textureColor.rgb, textureColor.a * opacity);
//     }
// `;

export const DISTORTED_IMAGE_SETTINGS = {
    displacementPeriodX: 7,
    displacementPeriodY: 7,
    waveIntensityX: .1,
    waveIntensityY: .1,
    timeScale: .55,
    seed: 0,
    // maskBegX: .0,
    // maskEndX: 0,
    // maskBegY: 0,
    // maskEndY: 0
}

export const DISTORTED_IMAGE_UNIFORMS = {
    texture1: { type: 't', value: new CanvasColorTexture().texture },
    opacity: { type: 'f', value: 1 },
    imageScale: { type: 'f', value: 1 },
    displacementPeriodX: { type: 'f', value: DISTORTED_IMAGE_FRAGMENT.displacementPeriodX },
    displacementPeriodY: { type: 'f', value: DISTORTED_IMAGE_FRAGMENT.displacementPeriodY },
    waveIntensityX: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.waveIntensityX },
    waveIntensityY: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.waveIntensityY },
    timeScale: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.timeScale },
    resolution: {
        type: 'v2',
        value: { x: 1, y: 1 }
    },
    seed: { type: 'f', value: 0 },
    // maskBegX: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.maskBegX },
    // maskEndX: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.maskEndX },
    // maskBegY: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.maskBegY },
    // maskEndY: { type: 'f', value: DISTORTED_IMAGE_SETTINGS.maskEndY }
};

export default class DistortedImageMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: DISTORTED_IMAGE_UNIFORMS,
            fragmentShader: DISTORTED_IMAGE_FRAGMENT,
            vertexShader: DISTORTED_IMAGE_VERTEX,
            transparent: true,
            side: FrontSide
        });
    }
}

export const DISTORTED_IMAGE_MATERIAL = new DistortedImageMaterial();