import { gsap } from 'gsap';

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

import { ScrollItem__BillboardHome } from './scroll/ScrollItem__BillboardHome';
import { ScrollItem__BlockAbout } from './scroll/ScrollItem__BlockAbout';
import { ScrollItem__BlockVip } from './scroll/ScrollItem__BlockVip';
import { ScrollItem__SliderParties } from './scroll/ScrollItem__SliderParties';
import { ScrollItem__SliderDefault } from './scroll/ScrollItem__SliderDefault';
import { ScrollItem__BlockEventIntro } from './scroll/ScrollItem__BlockEventIntro';
import { ScrollItem__Footer } from './scroll/ScrollItem__Footer';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction } from './_app/cuchillo/core/Interaction';
import { GetBy } from './_app/cuchillo/core/Element';
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Legal from './pages/Legal';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import Preloader from './layout/Preloader';
import Sidemenu from './layout/Sidemenu';
import Language from './_app/cuchillo/utils/Language';
import Scene from './3D/Scene';
import RenderTargetSketch from './3D/RenderTargetSketch';
import Cursor from "./_app/cuchillo/cursor/Cursor";

export default class Main {
    static stats;

    static init() {
        gsap.ticker.fps(60);

        Basics.id = 'Amnesia_v001'; // ID para cookies
        Language.init();

        Header.init();
        RenderTargetSketch.init();
        Scene.init();

        Metrics.init(() => Main.resize()); // Tamaños y resize
        Keyboard.enable(); // ESC para cerrar ventana
        Accessibility.init(); // Utils accesibilidad
        Statics.init(); // Si estamos en debug pinta un FPS counter
        Interaction.init({ ajax: true }); // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
        ControllerWindow.init(); // Control ventanas

        BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
        InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
        Cursor.init(document.body,{isMoveCancel:true});

        LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
        LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
        LoaderController.update = (progress) => Preloader.update(progress);
        LoaderController.onComplete = () => Main.setup();

        //this.doLaPenaInfo();

        // LOOP
        if (isDebug) {
            gsap.ticker.add(() => {
                Main.loopDebug();
            });
        } else {
            gsap.ticker.add(() => {
                Main.loop();
            });
        }

        Preloader.init();
        LoaderController.init();
    }

    static setup() {
        this.setupEvents();
        // INIT PAGE
        ControllerPage.init(Wrap.mainholder);
    }

    static setupEvents() {
        EventDispatcher.addEventListener(Page.ON_SHOW, () => {
            Cursor.start();
            Loading.stop();
        });
        EventDispatcher.addEventListener(Page.ON_HIDE, () => {
            Cursor.start();
            Sidemenu.hide();
            Metrics.RESIZE_MOBILE = true;
        });
        EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
            Loading.start();
        });

        EventDispatcher.addEventListener(Win.ON_HIDE, () => {
            Scroll.setEnabled(true);
        });
        EventDispatcher.addEventListener(Win.ON_SHOW, () => {
            Scroll.setEnabled(false);
        });
        EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => {
            Scroll.setEnabled(true);
        });
        EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
            Scroll.setEnabled(false);
            ControllerWindow.hideAll();
        });
    }

    static resize() {
        Header.resize();
        Sidemenu.resize();
        InterfaceCanvas.resize();
        BG.resize();
        ControllerPage.resize();
        RenderTargetSketch.resize();
        Cursor.resize();

        if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
    }

    static loop() {
        if (Scroll.isScrolling) Scroll.loop();

        ControllerPage.loop();
        BG.loop();
        Header.loop();
        Sidemenu.loop();
        InterfaceCanvas.loop();
        RenderTargetSketch.loop();
        Cursor.loop();
    }

    static loopDebug() {
        Statics.begin();
        this.loop();
        Statics.end();
    }

    static doLaPenaInfo() {
        console.log(
            '%cby La Pena',
            'background: #000; color: #bada55; padding:25px 100px;'
        );
        console.log('⟶ https://somoscuchillo.com');
        console.log('⟶ https://metodica.co');
        console.log('⟶ https://notfound.es/');
    }
}

if (
    document.attachEvent
        ? document.readyState === 'complete'
        : document.readyState !== 'loading'
) {
    Main.init();
} else {
    document.addEventListener('DOMContentLoaded', Main.init);
}
