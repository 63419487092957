import { GetBy } from '../_app/cuchillo/core/Element';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Basics } from '../_app/cuchillo/core/Basics';

export class Popups {
  static _popups = {};
  static _observer = null;
  static _options = {
    rootMargin: '0px 0px 100px 0px',
    threshold: 0
  };
    
  static init() {
    this.setupObserver();
    this.getPopups();
  };

  static getPopups() {
    let counter = 0;
    [...GetBy.selector("[data-popup]")].map(dom => {
      const id = 'popup' + counter;
      dom.setAttribute('id', id);
      const popup = new Popup(dom);
      this._popups[id] = popup;
      this._observer.observe(dom);
      counter++;
    });
  };

  static setupObserver() {
    if(this._observer) {
      this._observer.disconnect();
    }
    this._observer = new IntersectionObserver((entries) => { this.checkObserver(entries) }, this._options); 
  }

  static checkObserver(entries, observer) {
    
    entries.forEach(entry => {
        const id = Functions.getId(entry.target);
        const popup = this._popups[id];
        
        if(entry.isIntersecting) {          
            popup.show();
            this._observer.unobserve(entry.target);
        }
    });
  }


  static dispose() {
    if(this._observer) {  this._observer.disconnect();  }
    Object.entries(this._popups).map((item) => {item[1].dispose()});    
    this._popups = {}
    this._observer = null;
  }
};


export default class Popup {
  _popup;
  _close;

  _calls = {
    click: (e) => { this._click(e); }
  }

  constructor(__popup) {
    this._popup = __popup;
    this._close = GetBy.selector(".close", this._popup)[0];
  }

  show() {
    let id = this._popup.getAttribute('id');
    if (sessionStorage.getItem(id) == '1') {
        return;
    }

    this._popup.classList.add('show');
    this._setupEvents();
  }

  close() {
    this._popup.classList.remove('show');
    let id = this._popup.getAttribute('id');
    sessionStorage.setItem(id, 1);
  }

  _setupEvents() {
    this._popup.addEventListener(Basics.clickEvent, this._calls.click);
  }

  _click(e) {
    let closeButton = e.target.closest('.close');
    let container = e.target.closest('.popup-container');
    let a = e.target.closest('a');
    
    if (a || closeButton || container) {
        this.close();
    }
  }
  
  dispose() {
    this._popup.removeEventListener(Basics.clickEvent, this._calls.click);
    this._popup = null;
    this._close = null;
  }
}