import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import { gsap, Power1 } from 'gsap';

export default class Sidemenu extends _Sidemenu {
    static show(__call) {
        super.show(__call);
        document.querySelector('.hamburger').classList.add('sidemenu-open');
        document.querySelector('.hamburger').classList.add('locked');
    }
    static hide(__call) {
        super.hide(__call);
        document.querySelector('.hamburger').classList.remove('sidemenu-open');
        document.querySelector('.hamburger').classList.add('locked');
    }

    static show__effect(__d = 0) {
        gsap.set(this.container, { alpha: 0, y: '-100%' });

        gsap.to(this.container, {
            alpha: 1,
            y: 0,
            duration: 0.6,
            delay: __d,
            ease: Power1.easeInOut,
            onComplete: () => {
                this.afterShow();
                document.querySelector('.hamburger').classList.remove('locked');
            }
        });
    }

    static hide__effect(__d = 0) {
        gsap.to(this.container, {
            alpha: 0,
            y: '-100%',
            delay: 0,
            duration: 0.6,
            delay: __d,
            ease: Power1.easeInOut,
            onComplete: () => {
                this.afterHide();
                document.querySelector('.hamburger').classList.remove('locked');
            }
        });
    }
}
