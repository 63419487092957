import { FrontSide, ShaderMaterial } from 'three';

import CanvasColorTexture from '../textures/CanvasColorTexture';

export const RESPONSIVE_VIDEO_VERTEX = `
    varying vec2 vUv;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const RESPONSIVE_VIDEO_FRAGMENT = `
    precision mediump float;

    #define PI 3.1415926538

    uniform float opacity;
    uniform vec2 resolution;
    uniform vec2 resolutionMask;
    uniform vec2 mouse;
    uniform float angle;

    // Texture
    uniform sampler2D texture1;
    uniform float imageScale;
    
    // Mask
    uniform sampler2D mask;
    uniform float maskScale;

    // Darken
    uniform sampler2D textureDarken;
    uniform float darken;

    varying vec2 vUv;

    float scaleCenter = 0.5;

    void main() {
        vec2 uMouse = mouse * -0.5;
        
        // tip2: do the same for your mouse
        uMouse.y *= resolution.y / resolution.x;
        uMouse *= -1.;

        // Applies aspect ratio to plane
        vec2 newUVTexture = (vUv + uMouse - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);  // For image texture
        vec2 newUVMask = (vUv - vec2(scaleCenter)) * resolutionMask.xy + vec2(scaleCenter); // For mask
        vec2 newUVDarken = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter); // For dark layer
        
        // rotation around the X-axis
        float cosAngle = cos(angle);
        float sinAngle = sin(angle);

        // Center the coordinates before rotating
        vec2 centeredUV = newUVTexture - vec2(0.5, 0.5);

        // Rotate the coordinates around the X-axis
        vec2 rotatedUVMouse = vec2(centeredUV.x, centeredUV.y * cosAngle - (centeredUV.y * sinAngle / resolution.y));

        // Translate back after rotating
        rotatedUVMouse += vec2(0.5, 0.5);

        // Gets new centered coords for the texture and scales the image
        vec2 textureUVScale = (rotatedUVMouse - scaleCenter) * imageScale + scaleCenter;
        vec2 maskUVScale = (newUVMask - scaleCenter) * maskScale + scaleCenter;
        vec2 textureDarkenUVScale = (newUVDarken - scaleCenter) * 1. + scaleCenter;

        // Gets texture pixel color
        vec4 textureColor = texture2D(texture1, textureUVScale);
        vec4 maskColor = texture2D(mask, maskUVScale);
        vec4 textureBlack = texture2D(textureDarken, textureDarkenUVScale);

        // Darkens video
        vec4 darkened = mix(textureColor, textureBlack, darken);

        // Mixes with mask
        vec4 maskedColor = darkened * maskColor; 

        if (maskedColor.a < 0.05) { discard; }

        gl_FragColor = vec4(maskedColor.rgb, maskedColor.a * opacity);
    }
`;

export const RESPONSIVE_VIDEO_UNIFORMS = {
    texture1: { type: 't', value: new CanvasColorTexture().texture },
    mask: { type: 't', value: new CanvasColorTexture().texture },
    textureDarken: { type: 't', value: new CanvasColorTexture('#000000').texture },
    opacity: { type: 'f', value: 1 },
    imageScale: { type: 'f', value: 1 },
    maskScale: { type: 'f', value: 1 },
    darken: { type: 'f', value: 0 },
    angle: { type: 'f', value: 0 },
    resolution: {
        type: 'v2',
        value: { x: 1, y: 1 }
    },
    resolutionMask: {
        type: 'v2',
        value: { x: 1, y: 1 }
    },
    mouse: {
        type: 'v2',
        value: { x: 0, y: 0 }
    }
};

export default class ResponsiveVideoMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: RESPONSIVE_VIDEO_UNIFORMS,
            fragmentShader: RESPONSIVE_VIDEO_FRAGMENT,
            vertexShader: RESPONSIVE_VIDEO_VERTEX,
            transparent: true,
            side: FrontSide
        });
    }
}

export const RESPONSIVE_VIDEO_MATERIAL = new ResponsiveVideoMaterial();