import { GetBy } from '../_app/cuchillo/core/Element';
import { Functions } from '../_app/cuchillo/utils/Functions';

export class LazyPictures {
  static _pictures = {};
  static _observer = null;
  static _options = {
    rootMargin: '0px 0px 100px 0px',
    threshold: 0
  };
    
  static init() {
    this.setupObserver();
    this.getPictures();
    this.removeWebpInOldSafari();
  };

  static getPictures() {
    let counter = 0;
    [...GetBy.selector("picture.lazy")].map(dom => {
      const id = 'lazy-picture-' + counter;
      dom.setAttribute('id', id);
      const picture = new LazyPicture(dom);
      this._pictures[id] = picture;
      this._observer.observe(dom);
      counter++;
    });
  };

  static setupObserver() {
    if(this._observer) {
      this._observer.disconnect();
    }
    this._observer = new IntersectionObserver((entries) => { this.checkObserver(entries) }, this._options); 
  }

  static checkObserver(entries, observer) {
    entries.forEach(entry => {
        const id = Functions.getId(entry.target);
        const picture = this._pictures[id];
        
        if(entry.isIntersecting) {
            picture.lazyload();
            this._observer.unobserve(entry.target);
        }
    });
  }

  static removeWebpInOldSafari() {
    Object.values(this._pictures).forEach(picture => {
      let webpSources = picture.get().querySelectorAll('source[type="image/webp"]');
      webpSources.forEach(source => {
        source.remove();
      });
    });  
  }

  static isSafariUnderVersion(version) {
    var userAgent = navigator.userAgent;
      var isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
      var safariVersion = userAgent.match(/version\/(\d+)/i);

      if (isSafari && safariVersion) {
          var versionNumber = parseInt(safariVersion[1], 10);

          if (versionNumber < version) {
              return true;
          }
      }

      return false;
  }

  static dispose() {
    if(this._observer) {  this._observer.disconnect();  }
    
    Object.entries(this._pictures).map((item) => {item[1].dispose()});    

    this._pictures = {}
    this._observer = null;
  }
};


export default class LazyPicture {
    _picture;
    _sources;
    _img;
    _onImageLoadHandler;


    constructor(__picture) {
        this._picture = __picture;
        this._sources = GetBy.selector("source", this._picture);
        this._img = GetBy.selector("img", this._picture)[0];
        this._onImageLoadHandler = this.onImageLoad.bind(this);
    }

    get() {
      return this._picture;
    }
  
    lazyload() {
      this._sources.forEach((source) => {
          let srcset = source.getAttribute('data-srcset');
          if (srcset) {
              source.setAttribute('srcset', srcset);
              source.removeAttribute('data-srcset');
          }
      });

      if (LazyPictures.isSafariUnderVersion(16)) {  
        this._img.src = img.currentSrc;
        this._picture.classList.add('lazy-loaded');
        this._picture.classList.remove('lazy');
      } else {
          if (this._img.complete) {
            this._picture.classList.add('lazy-loaded');
            this._picture.classList.remove('lazy');
          } else {
              this._img.addEventListener('load', this._onImageLoadHandler);
          }
      }
    }

    onImageLoad() {
      this._picture.classList.add('lazy-loaded');
      this._picture.classList.remove('lazy');
      this._img.removeEventListener('load', this._onImageLoadHandler);
    }

    dispose() {
      this._picture = null;
      this._sources = null;
      this._img = null;
      this._onImageLoadHandler = null;
    }
}


