import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { ScrollItem__SliderImages__Item } from './ScrollItem__SliderDefault';

class ScrollItem__BlockEventIntro extends VScroll_Item {
    controller;
    _slider;
    _call;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.container = __link;
        this.content = GetBy.class('content', __link)[0];
        this.text = GetBy.class('info-wrapper', __link)[0];
        this.button = GetBy.class('play-video', __link)[0];
        this.playerButton = GetBy.class('cover-play-button', __link)[0];
        this.eventThumbnail = GetBy.class('event-wrapper', __link)[0];

        this.onPlay = this.play.bind(this);
        this.button.addEventListener('click', this.onPlay);

        this._slider = new SliderScroll(__link, {
            interaction: true,
            hasScrollbar: false,
            itemClass: ScrollItem__SliderImages__Item
        });

        this._call = () => {
            this.loop();
        };

        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };

        this.onMove = () => { };
    }

    loop() {
        this.animateText();
        if (this._slider) this._slider.loop();
    }

    animateText() {
        const alpha = Maths.normalize(1, 0.25, this.progressInside);
        this.text.style.opacity = alpha;
    }

    dispose() {
        this.button.removeEventListener('click', this.onPlay);
        this.onPlay = null;
        if (this._slider) this._slider.dispose();
        super.dispose();
    }

    resize(__w, __h) {
        super.resize(__w, __h);
        if (this._slider) this._slider.resize();
    }

    play() {
        this.playerButton.click();
        this.container.classList.add('playing');
    }
}

Scroll._registerClass('BlockEventIntro', ScrollItem__BlockEventIntro);
