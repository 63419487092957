import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';


export class EventCalendars {
  static _calendars = {};
    
  static init() {
    this.getCalendars();
  };

  static getCalendars() {
    let counter = 0;
    [...GetBy.selector("[data-calendar]")].map(dom => {
      const id = 'calendar' + counter;
      dom.setAttribute('id', id);
      const calendar = new EventCalendar(dom);
      this._calendars[id] = calendar;
      counter++;
    });
  };


  static dispose() {
    Object.entries(this._calendars).map((item) => {item[1].dispose()});    
    this._calendars = {}
  }
};


export default class EventCalendar {
  container;

  _calendar;
  _filters;
  _cards;
  _title;

  _calls = {
    click: (e) => { this._click(e); },
    filter: (id) => {this.filter(id); }
  }

  constructor(__calendar) {
    this.container = __calendar.parentNode;
    this._calendar = __calendar;
    this._filters = GetBy.selector("[data-calendar-filters] button", this.container);
    this._cards = GetBy.selector("[data-calendar-set] .event-thumbnail", this.container);
    this._title = GetBy.selector(".featured-title strong", this.container)[0];

    this._setupEvents();
    this._setupFilterNames();

    let currentFilter = document.querySelector('.event-filters .current', this.container);
    let currentID = currentFilter.getAttribute('data-calendar-filter-id');
    this.filter(currentID);

    currentFilter = null;
  }

  _setupEvents() {
    this.container.addEventListener(Basics.clickEvent, this._calls.click);
  }

  _setupFilterNames() {
    this._filters.forEach(filter => {
      filter.setAttribute('data-calendar-filter-name', filter.innerHTML);
    })
  }

  _click(e) {
    switch (e.target.tagName.toLowerCase()) {
      case "button":
        if (e.target.getAttribute("data-calendar-filter-id") !== null) {
          this.filter(e.target.getAttribute("data-calendar-filter-id"));
        }
        break;
    }
  }
  
  filter(id) {
    this._cards.forEach(card => {
      card.classList.add('hide');
    });
    
    let filtered = GetBy.selector(".event-thumbnail.f-" + id, this.container);
    filtered.forEach(card => {
      card.classList.remove('hide');
    });

    this._filters.forEach(filter => {
      filter.classList.remove('current');
    });
    
    let filter = document.querySelector('[data-calendar-filter-id="' + id + '"]', this.container);
    filter.classList.add('current');

    this._title.innerHTML = filter.getAttribute('data-calendar-filter-name');

    filter = null;
    filtered = null;
  }

  dispose() {
    this.container.removeEventListener(Basics.clickEvent, this._calls.click);
    this.container = null;
    this._calendar = null;
    this._filters = null;
    this._cards = null;
    this._title = null;
  }
}