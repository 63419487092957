import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__BlockAbout extends VScroll_Item {
  figure;
  text;
  items;
  stickers;
  totalItems;
  inc;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.figure = GetBy.class("__figure", __link)[0];
    this.text = GetBy.class("__text", __link)[0];
    this.items = [...GetBy.selector(".featured-text > span", __link)];
    this.stickers = [...GetBy.selector(".sticker", __link)];
    this.totalItems = this.items.length;
    this.inc = 1 / this.totalItems;

    this.onMove = () => {
      this.loop();
    };
  }

  loop() {
    this.animationVideo();
    this.animationText()
    this.animationStickers();
  }

  animationVideo() {
    const alpha = this.progress < .5 ? Maths.normalize(0.2, 0, this.progress) : Maths.normalize(0.8, 1, this.progress);
    this.figure.style.opacity = alpha;
  }

  animationText() {
    //GENERAL
    const alpha = this.progress < .5 ? Maths.normalize(0.15, .1, this.progress) : Maths.normalize(0.75, 0.8, this.progress);
    this.text.style.opacity = alpha;

    //SPANS
    const progress = this.progress;
    const norm = Maths.precission(Maths.normalize(.6, .1, progress));

    let p0 = 0;
    let p1 = this.inc;

    for (let i = 0; i < this.totalItems; i++) {
      const o = Math.max(.2, Maths.normalize(p1, p0, norm));

      this.items[i].style.opacity = o;



      p0 = p1;
      p1 = p1 + this.inc;
    }
  }

  animationStickers() {
    const alpha = this.progress < .5 ? Maths.normalize(0.2, .15, this.progress) : Maths.normalize(0.75, 0.8, this.progress);

    this.stickers.forEach(sticker => {
      sticker.style.opacity = alpha;
    });
  }

  dispose() {
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h);
  }
}

Scroll._registerClass('BlockAbout', ScrollItem__BlockAbout);