import gsap, { Power1 } from "gsap";

import { C, GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from '../_app/cuchillo/core/Basics';


export default class Preloader {
    _container;
    _progress;

    static init () {
        this._container = GetBy.id('Preloader');
        this._progress = GetBy.class('__progress', this._container);

        if(isDebug) C.remove(this._container)
    }

    static update (__progress) {
        if(isDebug) return;
            
        /*gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: __progress,
            ease: Power1.easeOut,
            duration: .1
        });*/
    }

    static hide (__call) {
        if(isDebug) {
            if(__call) __call();
            return;
        }
        if(__call) __call();
       /* gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: 1,
            ease: Power1.easeOut,
            duration: .1
        });*/
        gsap.to(this._container, {
            duration: .1,
            opacity: 0,
            delay: .2,
            onComplete: () => {
            this._container.style.display = "none";
            
            }
        });
    }
}
