import { GetBy } from '../_app/cuchillo/core/Element';
import { Functions } from '../_app/cuchillo/utils/Functions';


export class Stickers {
  static _stickers = {};
  static _observer = null;
  static _options = {
    rootMargin: '0px 0px 100px 0px',
    threshold: 0
  };
    
  static init() {
    this.setupObserver();
    this.getStickers();
  };

  static getStickers() {
    let counter = 0;
    [...GetBy.selector("[data-sticker]")].map(dom => {
      const id = 'sticker' + counter;
      dom.setAttribute('id', id);
      const sticker = new Sticker(dom);
      this._stickers[id] = sticker;
      this._observer.observe(dom);
      counter++;
    });
  };

  static setupObserver() {
    if(this._observer) {
      this._observer.disconnect();
    }
    this._observer = new IntersectionObserver((entries) => { this.checkObserver(entries) }, this._options); 
  }

  static checkObserver(entries, observer) {
    
    entries.forEach(entry => {
        const id = Functions.getId(entry.target);
        const sticker = this._stickers[id];
        
        if(entry.isIntersecting) {          
            sticker.show();
            this._observer.unobserve(entry.target);
        }
    });
  }


  static dispose() {
    if(this._observer) {  this._observer.disconnect();  }
    Object.entries(this._stickers).map((item) => {item[1].dispose()});    
    this._stickers = {}
    this._observer = null;
  }
};


export default class Sticker {
  _sticker;
  _img;
  _timeoutRemoveLatency;

  constructor(__sticker) {
    this._sticker = __sticker;
    this._img = GetBy.selector("picture", this._sticker)[0];
  }

  show() {
    const randomTime = (Math.random() * 0.8).toFixed(2);
    this._sticker.style.setProperty('--latency', randomTime);
    this._sticker.classList.add('show');

    let scope = this;
    this._timeoutRemoveLatency = setTimeout(function() {
      scope._sticker.style.setProperty('--latency', 0);
    }, 20);
  }
  
  dispose() {
    this._sticker = null;
    this._img = null;
    clearTimeout(this._timeoutRemoveLatency);
    this._timeoutRemoveLatency = null;
  }
}