import WebGLObject from './WebGLObject';
import TexturesController from './TexturesController';
import { PLANE_GEOMETRY } from '../../../3D/constants';
import { DISTORTED_IMAGE_MATERIAL, DISTORTED_IMAGE_SETTINGS } from './materials/DistortedImageMaterial';
import RenderTargetSketch from '../../../3D/RenderTargetSketch';

export default class DistortedImage extends WebGLObject {
    texture;


    constructor(opts = {}) {
        super(opts);

        this.geometry = PLANE_GEOMETRY;
        this.material = DISTORTED_IMAGE_MATERIAL.clone();
        this.diffuse = opts.diffuse;

        this.init(opts.modificator || 1);
    }

    init(__mod) {
        super.init();
        this.loadTexture();
        this.resize();

        this.material.uniforms.displacementPeriodX.value = DISTORTED_IMAGE_SETTINGS.displacementPeriodX;
        this.material.uniforms.displacementPeriodY.value = DISTORTED_IMAGE_SETTINGS.displacementPeriodY;
        this.material.uniforms.waveIntensityX.value = DISTORTED_IMAGE_SETTINGS.waveIntensityX * __mod;
        this.material.uniforms.waveIntensityY.value = DISTORTED_IMAGE_SETTINGS.waveIntensityY * __mod;
        this.material.uniforms.timeScale.value = DISTORTED_IMAGE_SETTINGS.timeScale;
    }

    loadTexture() {
        if (this.diffuse) {
            TexturesController.load({
                src: this.diffuse,
                material: this.material.uniforms.texture1,
                attribute: 'value'
            });
        }
    }

    update(opacity = 1) {
        super.update();

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const position = this.domPositionTo3D(x, y);
        this.pos.x = position.x + width * .5;
        this.pos.y = position.y - height * .5;

        const time = RenderTargetSketch.time();
        this.material.uniforms.seed.value = time;
        // this.material.uniforms.displacementPeriodX.value = DISTORTED_IMAGE_SETTINGS.displacementPeriodX;
        // this.material.uniforms.displacementPeriodY.value = DISTORTED_IMAGE_SETTINGS.displacementPeriodY;
        // this.material.uniforms.waveIntensityX.value = DISTORTED_IMAGE_SETTINGS.waveIntensityX;
        // this.material.uniforms.waveIntensityY.value = DISTORTED_IMAGE_SETTINGS.waveIntensityY;
        // this.material.uniforms.timeScale.value = DISTORTED_IMAGE_SETTINGS.timeScale;

        this.material.uniforms.opacity.value = opacity;
    }

    calculateResolution() {
        const width = this.size.x;
        const height = this.size.y;

        const planeAspect = this.mesh.scale.y / this.mesh.scale.x;
        let a1;
        let a2;

        if (height / width > planeAspect) {
            const h = (width / height) * planeAspect;
            // Canvas more horizontal than image
            a1 = 1;
            a2 = h;
        } else {
            // Canvas more vertical than image
            a1 = height / width / planeAspect;
            a2 = 1;
        }

        return {
            x: a1,
            y: a2
        };
    }

    resize() {
        const { x, y, width, height } = this.dom.getBoundingClientRect();
        const position = this.domPositionTo3D(x, y);

        this.position.x =
            this.pos.x = position.x + width * .5;
        this.position.y =
            this.pos.y = position.y - height * .5;
        this.position.z =
            this.pos.z = width;

        super.resize(width, height);

        this.material.uniforms.resolution.value = this.calculateResolution();
    }
}
