import { DISTORTION_SETTINGS } from "../_app/cuchillo/3D/materials/DistortedPixelMaterial";
import { Basics, isSmartphone, isTouch } from "../_app/cuchillo/core/Basics";
import { GetBy } from "../_app/cuchillo/core/Element";

export default class Movable {
  static _items = [];
  static _zIndex = 100;

  static set zIndex(n) { this._zIndex = n };
  static get zIndex() {
    this._zIndex++;
    return this._zIndex;
  }

  static _callUp = () => { Movable.up(); }

  container;
  _wrapper;
  _offset = { x: 0, y: 0, z: 1 };
  _position = {
    x: 0,
    y: 0,
    z: 0,
    rx: 0,
    ry: 0
  };
  _velocity = { x: 0, y: 0 };
  _isDown = false;
  _lastTime = 0;
  _calls = {
    down: (e) => { this.down(e) },
    up: (e) => { this.up(e) },
    move: (e) => { this.move(e) },
  }

  static init() {
    if(isTouch) return;

    [...GetBy.selector("[data-movable]")].map(dom => {
      this._items.push(new Movable(dom));
    });

    document.addEventListener(Basics.upEvent, Movable._callUp, true);
  }

  static up() {
    this._items.map(item => {
      if (item) item.up();
    });
  }

  static dispose() {
    this._items.map(item => {
      if (item) item.dispose();
    });

    this._items = [];

    document.removeEventListener(Basics.upEvent, Movable.up);
  }

  constructor(__item) {
    this.container = __item;

    if (this.container.parentNode) {

      if (this.container.parentNode.hasAttribute('data-movable-container')) {
        this._wrapper = this.container.parentNode;
        this._wrapper.addEventListener(Basics.moveEvent, this._calls.move);
      }
    }

    this.container.addEventListener(Basics.downEvent, this._calls.down, true);
  }

  down(e) {
    this._isDown = true;
    this._lastTime = Date.now();

    this._offset = {
      x: isTouch ? e.touches[0].screenX : e.clientX,
      y: isTouch ? e.touches[0].screenY : e.clientY,
      z: Movable.zIndex
    };

    this._velocity = { x: 0, y: 0 };

    this.container.classList.add('moving');
    document.body.classList.add('--dragging');
    DISTORTION_SETTINGS.strength = .1;
  }

  move(e) {
    if (!this._isDown) return;

    const currentTime = Date.now();
    const timeDiff = currentTime - this._lastTime;

    const pos = {
      x: isTouch ? e.touches[0].screenX : e.clientX,
      y: isTouch ? e.touches[0].screenY : e.clientY,
      z: this._offset.z
    };

    const dx = pos.x - this._offset.x;
    const dy = pos.y - this._offset.y;

    this._velocity = {
      x: dx / timeDiff * 16,
      y: dy / timeDiff * 16
    };

    this._position.x += dx;
    this._position.y += dy;
    this._position.z = pos.z;

    this._offset = {
      x: pos.x,
      y: pos.y,
      z: pos.z,
    };

    this._lastTime = currentTime;

    this.updatePosition();
  }

  up() {
    this._isDown = false;
    this.applyInertia();
    this.container.classList.remove('moving');
    document.body.classList.remove('--dragging');
    DISTORTION_SETTINGS.strength = 0.01;
  }

  applyInertia() {
    if (Math.abs(this._velocity.x) > 0.1 || Math.abs(this._velocity.y) > 0.1) {
      this._position.x += this._velocity.x;
      this._position.y += this._velocity.y;

      this.container.style.transform = `translate3d(${this._position.x}px, ${this._position.y}px, ${this._position.z}px)`;

      this._velocity.x *= 0.95;
      this._velocity.y *= 0.95;

      this.updatePosition();

      requestAnimationFrame(() => this.applyInertia());
    }
  }

  updatePosition() {

    if (this._wrapper) {
      const rect = this.container.getBoundingClientRect();
      const wrapperRect = this._wrapper.getBoundingClientRect();


      if (rect.left < wrapperRect.left) {
        this._position.x += wrapperRect.left - rect.left;
        this._velocity.x = -this._velocity.x * 0.5;
      }
      if (rect.top < wrapperRect.top) {
        this._position.y += wrapperRect.top - rect.top;
        this._velocity.y = -this._velocity.y * 0.5;
      }
      if (rect.right > wrapperRect.right) {
        this._position.x -= rect.right - wrapperRect.right;
        this._velocity.x = -this._velocity.x * 0.5;
      }
      if (rect.bottom > wrapperRect.bottom) {
        this._position.y -= rect.bottom - wrapperRect.bottom;
        this._velocity.y = -this._velocity.y * 0.5;
      }
    }

    this.container.style.transform = `translate3d(${this._position.x}px, ${this._position.y}px, ${this._position.z}px)`;
    this.container.style.zIndex = this._position.z;
  }

  dispose() {
    this.container.removeEventListener(Basics.downEvent, this._calls.down);
    if (this._wrapper) {
      this._wrapper.removeEventListener(Basics.moveEvent, this._calls.move);
    }
    

    this.container = null;
    this._wrapper = null;
    this._offset = null;
    this._position = null;
    this._velocity = null;
    this._isDown = null;
    this._lastTime = null;
  }
}
