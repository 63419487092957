import { Group, AmbientLight } from 'three';

import RenderTargetSketch from './RenderTargetSketch';
export default class Scene {
    static _initialized = false;
    // static _rotation = 0;
    // static face = 0;

    // static dom;
    // static pyramid;

    static group = new Group();
    // static rotationGroup = new WebGLGroup({ rotFactor: .083 });
    static ambientLight;

    static init() {
        RenderTargetSketch.group.add(this.group);
        // this.group.add(this.rotationGroup);

        this.init3dItems();
    }

    static start() {
        if (!this._running) {
            this._running = true;
            // DebugPane.init();
        }
    }

    static stop() {
        if (this._running) {
            this._running = false;
            // DebugPane.init();
        }
    }

    static add(element) {
        this.group.add(element);
    }

    static remove(element) {
        this.group.remove(element);
    }

    static init3dItems() {
        // const dom = GetBy.selector('#scene-target [data-3d-target]')[0];
        // this.pyramid = new Pyramid({
        //     dom,
        //     // diffusion: ['/assets/images/1.jpg', '/assets/images/2.jpg', '/assets/images/3.jpg', '/assets/images/4.jpg'],
        //     // diffusion: ['/assets/images/texture.jpeg', '/assets/images/texture.jpeg', '/assets/images/texture.jpeg', '/assets/images/texture.jpeg'],
        //     posters: ['/assets/images/3.jpg', '/assets/images/3.jpg', '/assets/images/3.jpg', '/assets/images/3.jpg'],
        //     videos: [
        //         'https://player.vimeo.com/progressive_redirect/playback/949244941/rendition/1080p/file.mp4?loc=external&log_user=0&signature=5f8ee0c8000f2f87913a2721770faa0d2c4b9367ba1827d3d3d6df82c85250bf',
        //         'https://player.vimeo.com/progressive_redirect/playback/949244920/rendition/1080p/file.mp4?loc=external&log_user=0&signature=ca54ad88ea8b505d1f72240c19920de352bfb6af05b8b6b201c85db5c8fa5dd8',
        //         'https://player.vimeo.com/progressive_redirect/playback/949244920/rendition/1080p/file.mp4?loc=external&log_user=0&signature=ca54ad88ea8b505d1f72240c19920de352bfb6af05b8b6b201c85db5c8fa5dd8',
        //         'https://player.vimeo.com/progressive_redirect/playback/949244920/rendition/1080p/file.mp4?loc=external&log_user=0&signature=ca54ad88ea8b505d1f72240c19920de352bfb6af05b8b6b201c85db5c8fa5dd8'
        //     ],
        //     mask: '/assets/images/mask.png'
        // });
        // this.rotationGroup.add(this.pyramid);

        this.ambientLight = new AmbientLight('#ffffff', 3);
        RenderTargetSketch.group.add(this.ambientLight);

        this.show();
    }

    static show(cb = () => { }) {
        this._initialized = true;

        cb();
    }

    static loop() {
        if (!this._running || !this._initialized) return;

        // this.rotationGroup.rot.y = -1 * Math.PI / 2 * this.face;
        // this.rotationGroup.update();

        // this.pyramid.update();
    }

    static resize() {
        // this.pyramid.resize();
    }

    static dispose() {
        // this.rotationGroup.remove(this.pyramid);
        // this.pyramid.dispose();
        // this.group.remove(this.rotationGroup);
        // this.rotationGroup.dispose();
        RenderTargetSketch.group.remove(this.group);
    }
}
